import React from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import {
  Button, Box, Flex, Text, Stack, Heading, useBreakpointValue, Spacer, Icon, useColorModeValue,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import Layout from '../components/layout';
import EnquireButton from '../components/enquire-button';

type AdjacentArtwork = {
  frontmatter: {
    title: string;
  }
  fields: {
    slug: string;
  }
}

type Props = {
  pageContext: {
    next: AdjacentArtwork;
    previous: AdjacentArtwork;
  }
  data: {
    category: {
      frontmatter: {
        label: string;
      }
    }
    artwork: {
      frontmatter: {
        title: string;
        description: string;
        image: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
      }
    }
  }
}

const Artwork: React.FC<Props> = ({ data, pageContext }) => {
  const {
    title, description, image,
  } = data.artwork.frontmatter;
  const { label: categoryLabel } = data.category.frontmatter;

  const { next, previous } = pageContext;

  const rightImageMargin = useBreakpointValue({ base: 0, md: 8 });
  return (
    <Layout titleOverride={title} descriptionOverride={description}>
      <Stack direction={{ base: 'column', md: 'row' }} py={{ base: 12, md: 24 }}>
        <Flex p={8} flex={1} align="center" justify="center">
          <Stack spacing={6} w="full" maxW="lg">
            <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
              <Text
                position="relative"
                _after={{
                  content: "''",
                  width: 'full',
                  height: useBreakpointValue({ base: '12px', md: '16px', lg: '24px' }),
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: useColorModeValue('pink.200', 'pink.800'),
                  zIndex: -1,
                }}
              >
                {title}
              </Text>

            </Heading>
            <Heading fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}>
              <Text color={useColorModeValue('pink.500', 'pink.100')}>
                {categoryLabel}
              </Text>
            </Heading>

            <Text fontSize={{ base: 'md', lg: 'lg' }} color={useColorModeValue('gray.600', 'gray.300')}>
              {description}
            </Text>

          </Stack>
        </Flex>
        <Flex flex={2}>
          <Box width="100%" mr={rightImageMargin}>
            <Img
              fluid={image.childImageSharp.fluid}
              alt={title}
              style={{ margin: '0 auto' }}
            />
          </Box>
        </Flex>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={4}
        mx="auto"
        maxWidth="xl"
        mb={12}
        px={4}
      >
        {previous ? (
          <Flex flex={1} justifyContent="center">

            <Button as={GatsbyLink} to={previous.fields.slug} title={previous.frontmatter.title}>
              <Icon
                as={ChevronLeftIcon}
                w={6}
                h={6}
                mr={2}
              />
              <Text
                mr={2}
                fontSize={{ base: 'sm', md: 'md' }}
                whiteSpace="break-spaces"
              >
                Prev
              </Text>
            </Button>
          </Flex>
        ) : <Spacer />}
        <Flex flex={1} justifyContent="center">
          <EnquireButton />
        </Flex>
        {next ? (
          <Flex flex={1} justifyContent="center">

            <Button as={GatsbyLink} to={next.fields.slug} title={next.frontmatter.title}>
              <Text
                ml={2}
                fontSize={{ base: 'sm', md: 'md' }}
                whiteSpace="break-spaces"
              >
                Next
              </Text>
              <Icon
                as={ChevronRightIcon}
                w={6}
                h={6}
                ml={2}
              />

            </Button>
          </Flex>
        ) : <Spacer />}
      </Stack>
    </Layout>
  );
};

export const artworkQuery = graphql`
  query Artwork ($slug: String!, $categorySlug: String!) {
    artwork: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
    category: markdownRemark(fields: { itemType: { eq: "categories" } }, frontmatter: {slug: {eq: $categorySlug}}) {
      frontmatter {
        label
      }
    }
  }`;

export default Artwork;
